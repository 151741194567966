import { useEffect, useState } from 'react';
import axios from 'axios';

import { logger } from '@/common/initAPM';

import { getInternalClientSideServiceUrl } from '../../packages/environment-utils';

/**
 * Represents a list of SSL subscriptions
 * @extends {Array}
 * @property {array} subscriptions SSL subscriptions
 */
class SSLSubscriptions extends Array {
  /**
   * @param {array} subscriptions A list of SSL subscriptions
   */
  constructor(subscriptions) {
    super(...subscriptions);
  }
}

/**
 * Fetches available SSL subscriptions
 * @returns {{data: SSLSubscriptions, loading: boolean}} Available SSL subscriptions
 */
const useSSLSubscriptions = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const bffUrl = getInternalClientSideServiceUrl({
    subdomain: 'bff.pcx',
    hasStaging: true,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const response = await axios.get(
          `${bffUrl}/api/ssl/onboarding/views/available-subscriptions`,
          {
            withCredentials: true,
          },
        );

        if (!Array.isArray(response.data?.subscriptions)) {
          throw new Error('Invalid ssl subscriptions response data format');
        }

        setData(response.data.subscriptions);
      } catch (e) {
        logger.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { data: new SSLSubscriptions(data), loading };
};

export default useSSLSubscriptions;

export { SSLSubscriptions };
