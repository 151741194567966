import useSSLSubscriptions from '@/components/services/pki';

import SubscriptionsList from '../services/subscriptions/subscriptions-list';

const useInactivePlans = (props) => {
  const {
    subscriptions: { data: subscriptions, loading, error },
    msslTickets: { data: mssl },
  } = props;

  const { data: sslSubscriptions, loading: sslSubscriptionsLoading } =
    useSSLSubscriptions();

  if (loading || sslSubscriptionsLoading || error) {
    return {
      websiteSecurity: {},
      backups: {},
      ssl: {},
    };
  }

  const inactiveWSSPlans = subscriptions
    .withWebSecurity()
    .inactive()
    .groupByProductID();

  const inactiveBackupsPlans = subscriptions
    .withBackups()
    .inactive()
    .groupByProductID();

  let inactiveSSL = new SubscriptionsList(
    ...subscriptions.withInactiveMSSL(mssl || []),
    ...subscriptions.withInactiveSSL(sslSubscriptions),
  );

  inactiveSSL = inactiveSSL.groupByProductID();

  return {
    websiteSecurity: inactiveWSSPlans,
    backups: inactiveBackupsPlans,
    ssl: inactiveSSL,
  };
};

export { useInactivePlans };
